import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
  ReferenceBanner,
  ReferenceBannerContent,
} from "@igloonet-web/shared-ui"

import Top from "../../../images/reference/snowboard-zezula/top.jpg"
import Logo from "../../../images/reference/snowboard-zezula/logo.png"
import Header from "../../../images/reference/snowboard-zezula/header-marketing.png"
import Petr from "../../../images/reference/snowboard-zezula/reaction-devel.png"
import Sideimg from "../../../images/reference/snowboard-zezula/screen-1.png"

class SnowboardZezula extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.9),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na e-shopový systém Snowboard Zezula | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//snowboard-zezula.cz"
          heading="Reference na e-shopový systém Snowboard Zezula"
          odstavec="srpen 2008 - srpen 2021"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Snowboard Zezula je specializovaný prodejce snowboardového vybavení a doplňků, který nabízí nejširší sortiment snowboardového vybavení v ČR."
            secondaryText="Od roku 1996 provozují prodejnu v Brně a od roku 2004 také e-shop. Jako jedni z prvních pochopili důležitost internetových obchodů a dělají vše pro to, aby zůstali jedničkou jakožto prodejci i výrobci snowboardů"
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Převzít vývoj e-shopu www.snowboard-zezula.cz a umožnit
                rychlejší vývoj požadovaných funkcí a opravu chyb.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>
                  vývoj vlastního e-shopového systému pro maloobchod i
                  velkoobchod
                </li>
                <li>technické konzultace, vývoj a testování nových funkcí</li>
                <li>konzultace grafického řešení</li>
                <li>responzivní design pro frontendovou část e-shopu</li>
                <li>kompletní řešení online marketingu</li>
                <li>
                  kompletní zajištění hostingu a IT prostředků na e-shopu i
                  prodejně
                </li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Historie spolupráce</TitleH2>

              <p>
                Na Snowboard Zezula jsme začali pracovat původně jako vývojářská
                výpomoc. Poměrně brzy jsme kompletně převzali vývoj celého
                e-shopu a výrazně rozšířili naši spolupráci. Kvůli mnoha
                omezením starého systému jsme v roce 2012 spustili e-shop na
                kompletně novém jádře spolu s novým frontendem.
              </p>
              <p>
                Od počátku spolupráce řešíme pro Snowboard Zezula nejen samotný
                vývoj, ale i konzultace ohledně dalšího vývoje, grafiky a
                propagace.
              </p>
              <p>
                Od roku 2011 jsme také převzali správu PPC kampaní a SEO, v
                současné době již řešíme kompletní online marketing Snowboard
                Zezula.
              </p>
              <p>
                V srpnu 2021 jsme web předali ke správě internímu týmu e-shopu.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            wide
            image={Petr}
            alt=""
            text="Při výběru obchodního partnera je pro mě důvěra vždy na prvním místě,
            proto jsem si vybral společnost igloonet."
            name="Petr Schwarz"
            position="ředitel"
          />
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default SnowboardZezula
